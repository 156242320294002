import React from 'react';
import { Link } from 'react-router-dom';
import './Shipping.css';

const Shipping = () => {
  const Header = () => <div className="header" />;
  const Footer = () => <div className="footer" />;
  const Copyright = () => {
    return (
      <div className="copyright">
        &copy; 2023 Mesantos
      </div>
    )
  }
  const Return = () => {
    return (
      <div className="return">
        <Link to="/"><p>Return</p></Link>
      </div>
    )
  }

  return (
    <div className="bark-background">
      <div className="pseudo-background" />
      <div className="container">
        <div>
          <Header />
          <section>
            <h2>Shipping Policy</h2>
            <p>
              <strong>Shipping Rates:</strong>
            </p>
            <p>Shipping charges for your order will be calculated and displayed at checkout.</p>
            <p>
              <strong>Delivery Time:</strong>
            </p>
            <p>Estimated delivery time will be provided once your order is confirmed. Delivery times may vary depending on your location.</p>
            <p>
              <strong>Order Confirmation:</strong>
            </p>
            <p>You will receive a confirmation email once your order has been shipped containing your tracking number(s). The tracking number will be active within 24 hours.</p>
            <p>
              <strong>International Shipping:</strong>
            </p>
            <p>We currently do not offer international shipping.</p>
          </section>
          <Footer />
          <Return />
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default Shipping;
