import React from 'react';
import { Link } from 'react-router-dom';
import './T&C.css';

const Terms = () => {
  const Header = () => <div className="header" />;
  const Footer = () => <div className="footer" />;
  const Copyright = () => {
    return (
      <div className="copyright">
        &copy; 2023 Mesantos
      </div>
    )
  }
  const Return = () => {
    return (
      <div className="return">
        <Link to="/"><p>Return</p></Link>
      </div>
    )
  }

  return (
    <div className='bark-background'>
      <div className='pseudo-background' />
      <div className='container'>
        <div>
          <Header />
          <section>
            <h2>Terms & Conditions</h2>
            <p>Welcome to swordslinger.org!</p>
            <p>These Terms and Conditions outline the rules and regulations for the use of this website.</p>
            <p>
              By accessing this website, we assume you accept these terms and conditions. Do not continue to use swordslinger.org if you do not agree to take all of the terms and conditions stated on this page.
            </p>
            <p>
              <strong>Cookies:</strong> We may employ the use of cookies at our discretion. By accessing swordslinger.org, you agreed to use cookies in agreement with our Privacy Policy.
            </p>
            <p>
              <strong>License:</strong> Unless otherwise stated, swordslinger.org and/or its licensors own the intellectual property rights for all material on SwordSlinger.org. All intellectual property rights are reserved. You may access this from SwordSlinger.org for your personal use subjected to restrictions set in these terms and conditions.
            </p>
            <p>
              <strong>Restrictions:</strong> You are specifically restricted from all of the following:
            </p>
            <p>• Publishing any swordslinger.org material in any other media without permission.</p>
            <p>• Selling, sublicensing, and/or otherwise commercializing any swordslinger.org material without permission.</p>
            <p>• Using swordslinger.org in any way that is or may be damaging to this website.</p>
            <p>• Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to swordslinger.org.</p>
            {/* <p>
              <strong>Your Content:</strong> In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images, or other material you choose to display on SwordSlinger.org. By displaying Your Content, you grant SwordSlinger.org a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.
            </p>
            <p>
              <strong>Reservation of Rights:</strong> We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request.
            </p> */}
          </section>
          <Footer />
          <Return />
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default Terms;


