import React from 'react'
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import './UI_layout.css'
import ss_cover from '../images/SS_COVER_OG_Sword&Berries-sm.jpg';
import logo from '../images/serpentwise_SS-site-logo_lrg-BLACK.png';
import logo_2 from '../images/favicon.png';
import { Tooltip } from 'react-tooltip';
// import logo from '../images/serpentwise_SS-site-logo_lrg-black&white.png';
// import header_img from '../images/seamless-frame_75res_full.png';
// import footer_img from '../images/footer.png';

const UI_layout = () => {

  // const scrollToTop = () => {
  //   const targetPosition = 0;
  //   const startPosition = window.pageYOffset;
  //   const distance = targetPosition - startPosition;
  //   const duration = 500; // Duration of the scroll animation in milliseconds
  //   let startTimestamp = null;

  //   const step = (timestamp) => {
  //     if (!startTimestamp) startTimestamp = timestamp;
  //     const progress = timestamp - startTimestamp;
  //     const percentage = Math.min(progress / duration, 1);

  //     window.scrollTo(0, startPosition + distance * percentage);

  //     if (progress < duration) {
  //       requestAnimationFrame(step);
  //     }
  //   };

  //   requestAnimationFrame(step);
  // };
   
  const Paragraphs = () => {
    return (
      <div className='paragraphs'>
        <p id='para_about'><a href="#about">About |</a></p>
        <p id='para_author'><a href="#author">Author |</a></p>
        <p id='para_contact'><a href="#contact">Contact</a></p>
      </div>
    )
  }
  
  const Ss_cover = () => {
    return (
      <div className="ss_cover_container">    
        <img id="ss_cover_img" src= {ss_cover} alt="Sword_Slinger_Cover" className='hover-effect' />
      </div>
    )
  }
  
  const Pre_order = () => {
    return (
      <div id='pre_order' className="pre_order_container">
        <p id='para_purchase'><a href="https://serpentwise.sellfy.store/p/swordslinger-ebook/" data-tooltip-id="TOOL_TIP" data-tooltip-content="PDF, ePUB, MOBI" data-text="Purchase" id="jju98j" class="sellfy-buy-button sellfy-buy-button-custom">Purchase</a></p>
          <Tooltip id="TOOL_TIP" place="bottom" content="PDF, ePUB, MOBI" />
        <p id='para_pre_order'><a href="https://serpentwise.sellfy.store/p/swordslinger-pre-order/" data-tooltip-id="TOOL_TIP" data-tooltip-content="Print and Audiobook (MP3, M4B, AAC) formats are available for pre-order" data-text="" id="ghortj" class="sellfy-buy-button sellfy-buy-button-custom">Pre-order</a></p>
          <Tooltip id="TOOL_TIP" place="bottom" content="Print and Audiobook (MP3, M4B, AAC) formats are available for pre-order" />
      </div>
    )
  }

  const Purchase_paragraph = () => {
    // option 2
    // const handleEbookClick = () => {
    //   const popupWidth = 600;
    //   const popupHeight = 400;
    //   const siteWidth = window.innerWidth;
    //   const siteHeight = window.innerHeight;
    //   const left = (siteWidth - popupWidth) / 2;
    //   const top = (siteHeight - popupHeight) / 2;
    //   const popupFeatures = `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes`;
  
    //   window.open(
    //     "https://serpentwise.sellfy.store/checkout/details",
    //     "Ebook Purchase",
    //     popupFeatures
    //   );
    // };
    //option 1
    // const handleEbookClick = () => {
    //   const left = (window.screen.width - 600) / 2;
    //   const top = (window.screen.height - 400) / 2;
    //   window.open(
    //     // "https://serpentwise.sellfy.store/p/swordslinger-ebook/",
    //     "https://serpentwise.sellfy.store/checkout/details",
    //     "Ebook Purchase",
    //     `width=1000,height=600,left=${left},top=${top},scrollbars=yes`
    //   );
    // };
    return (
      <div className='purchase_paragraphs'>
        <a href="https://serpentwise.sellfy.store/p/swordslinger-ebook/" id='para_ebook' className='para_ebook'>
          Ebook |
        </a>
        
        {/* <a href="#" id='para_ebook' className='para_ebook' onClick={handleEbookClick}>Ebook |</a>  */}
        {/* <p id='para_ebook' className='para_ebook'>Ebook |</p> */}
        <Link to="/pre-order" id='para_audiobook'className='para_audiobook' data-tooltip-id="TOOL_TIP" data-tooltip-content="COMING SOON">Ebook |</Link>
        <Link to="/pre-order" id='para_audiobook'className='para_audiobook' data-tooltip-id="TOOL_TIP" data-tooltip-content="COMING SOON">Audiobook |</Link>
        <Tooltip id="TOOL_TIP" place="bottom" content="COMING SOON" />
        <Link to="/pre-order" id='para_print'className='para_print'data-tooltip-id="TOOL_TIP" data-tooltip-content="COMING SOON">Print</Link>
        <Tooltip id="TOOL_TIP" place="bottom" content="COMING SOON" />
      </div>
    )
  }

  const Header = () => {
    return (
      <div className="header">

      </div>
    )
  }

  const About_section = () => (
    <section id="contents">
      <h2 className="about_section"><a id="about"></a>About</h2>
      <p>
      "Kriepr va sin Sturnfut was destined to inherit his father's chieftainhood when a band of marauders hacked those promises apart. Alone and confused, Kriepr has to learn one of the hardest lessons of life: resilience. With derring-do he survives, upon the shoulders of masters he climbs, until his feet find him as: the swordslinger—avenger of Jovgar. But as Kriepr stumbles upon others sharing similar paths and stories, is vengeance enough? 
      </p>
      <p>
      In the nascent Tsingan Empire, Amoalíc of Klov Dün was fighting against stigma when a discovery swept away the peace she'd longed for. Fearfully, she is forced to confront a pivotal question: can all battles be won with tact, or can violent intentions prove too intense a force for balance? 
      </p>
      <p>
      An ancient tale of maturation and bravado set in a Bronze Age-esque land: a girl and a boy must find their footing after losing everything, becoming embroiled not only in the affairs of the bigger world at large, but their very own demons." 
      </p>
      <p></p>
      <p>“A compelling fantasy about the struggles of two lovers seeking retribution.” - <i>Kirkus Reviews</i></p>
      <p></p>
      <p>“In all, this is an original, largely engaging, and introspective book.” - <i>Decillis, reader</i></p>
      <p></p>
      <p><b>Page Count:</b> approx. 565</p>
      <p><b>Genre:</b> Literary Fantasy</p>
      <p><b>Suggested Age:</b> 16+ Mature</p>

      <p><b>Tax-inclusive Pricing:</b> (<i>what you see is what you pay</i>)</p>
    </section>
  );

  const Author_section = () => (
    <section>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <h2 className="author_section"><a id="author"></a>Author</h2>
      <p>
        Swordslinger is written by <b>Mesantos</b>, who was born in the United States. Having grown up underprivileged, it was Mesantos' dream to write quality literature for people of all age groups and types.
      </p>
      <p>
        It is Mesantos' hope that this book and many more to come will in the future help to fund charities of all varieties, but particularly those serving goals in rehabilitation and mental health.
      </p>
    </section>
  );

  const Contact_section = () => {
    const [email, setEmail] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const Submit = (event) => {
      event.preventDefault();
      console.log('Form submitted');
      console.log('Email:', email);
  
      // POST email to the server
      fetch('https://sword-slinger-website-backend.onrender.com/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to post email to the server');
          }
        })
        .then((data) => {
          console.log(data.message);
          setFormSubmitted(true);
          // Optionally, you can redirect the user or perform other actions after form submission
          // Replace the following line with the desired action
          console.log('Form submission success!');
        })
        .catch((error) => {
          console.error('Error during form submission:', error);
        })
        .finally(() => {
          // Reset the formSubmitted state and clear the email input
          // setFormSubmitted(false);
          setEmail('');
          console.log("You are now subscribed.")
          // Scroll to the top of the page
          console.log('Scrolling to the top?');
          window.scrollTo(0, 0);
        });
    };
  
    return (
      <section>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <h2 className="contact_section"><a id="contact"></a>Contact</h2>
        <p>
          Follow us on X at: <a href="https://twitter.com/SerpentWise_" id="x">@SerpentWise</a> or <a href="https://twitter.com/AuthorMesantos" id="x">@AuthorMesantos</a>
        </p>
        <p>
          <b>Newsletter</b>
        </p>
        <p>
          <i>Get updates on audio and print releases of Swordslinger, as well as on local events, video uploads (separate from YT alerts), and new literary releases. We will not send you any third-party marketing or sell your email(s) to marketers or otherwise. That is against our business policy.</i><br />
          <form className = "email_form" onSubmit={Submit}>
            <div id="email_input"> 
              <input
                type="text"
                name="email"
                id="email_input"
                placeholder="Email Address"
                value={email}
                onChange={handleEmailChange}
              /> <button type="submit" id="submitted_button" onClick={Submit}>Subscribe</button> 
            </div>
            {formSubmitted && <p id="submitted_msg">You are now subscribed!</p>}
          </form>
        </p>
        <p>
          <b>Questions?</b>
        </p>
        <p>
          Please contact us at: <a href="serpentwise@protonmail.com">serpentwise@protonmail.com</a>
        </p>
        <p>
          <b>More from SerpentWise</b>
        </p>
        <p>
          Subscribe to Mesantos' publishing business, SerpentWise, on <a href="https://Youtube.com/@SerpentWise">Youtube.com/@SerpentWise</a> for more book, writing, and storytelling-related content, as well as to keep up on SerpentWise's movements.
        </p>
      </section>
    );
  };  

  // const ScrollToTopLink = () => (
  //   <div className='scroll-to-top'>
  //     <p onClick={scrollToTop}>Scroll to Top</p>
  //   </div>
  // );

  const Footer = () => {
    return (
      <div className="footer">

      </div>
    )
  }

  const Policies = () => {
    return (
      <div className="policies">
        <Link to="/terms" className="policy-link">Terms & Conditions</Link>
        <Link to="/shipping" className="policy-link">Shipping Policy</Link>
        <Link to="/privacy" className="policy-link">Privacy Policy</Link>
      </div>
    );
  };

  const Copyright = () => {
    return (
      <div className="copyright">
        &copy; 2023 Mesantos
      </div>
      //  <Link to="/log_in" className="copyright">
      //   &copy; 2023 Mesantos
      // </Link>
    )
  }

  return (
  
    <div id='body'>
      
      <div className='bark-background'>
        <div className='pseudo-background' />
          <div className='container'>
            <div className="logo_container">    
              <img id="logo" src= {logo} alt="logo" className='logo_img' />
            </div>
              <Paragraphs />
              <Ss_cover />
              <Pre_order />
              {/* <Purchase_paragraph /> */}
              <Header />
              <About_section />
              <Author_section />
              <Contact_section />
              {/* <ScrollToTopLink /> */}
              <Footer />
              <Copyright />
            <Link to="/log_in" className="logo_button_container">    
              <img id="logo_button_image" src= {logo_2} alt="logo_button_image" />
            </Link>
              <Policies />
          </div>
        </div>
      </div>
  );
}

export default UI_layout;


  
      



