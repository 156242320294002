import React from 'react'
import { BrowserRouter, Route, Routes, Link, } from 'react-router-dom'
import { useState } from 'react'
import './App.css';
import UI_layout from './react_components/UI_layout'
import Log_in from './react_components/Log_in'
import Admin_Home from './react_components/Admin_Home'
import Terms from './react_components/T&C'; 
import Shipping from './react_components/Shipping';
import Privacy from './react_components/Privacy';
import PreOrder from './react_components/Pre-Order';    

function App() {

  return (

  <BrowserRouter>
  
    {/* <div id='body'> */}

      {/* <div className='pseudo-background' /> */}
      {/* <div className='bark-background' /> */}
      
      <div className='container'>
          {/* <UI_layout /> */}
          <Routes>
            <Route path='/' element={<UI_layout />} />
            <Route path='/log_in' element={<Log_in />} />
            <Route path='/admin_home' element={<Admin_Home />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/pre-order" element={<PreOrder />} />
          </Routes>
      
      </div>

    {/* </div> */}

  </BrowserRouter>

  );
}

export default App;
