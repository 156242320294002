import React from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css';

const Privacy = () => {
  const Header = () => <div className="header" />;
  const Footer = () => <div className="footer" />;
  const Copyright = () => {
    return (
      <div className="copyright">
        &copy; 2023 Mesantos
      </div>
    )
  }
  const Return = () => {
    return (
      <div className="return">
        <Link to="/"><p>Return</p></Link>
      </div>
    )
  }

  return (
    <div className='bark-background'>
      <div className='pseudo-background' />
      <div className='container'>
        <div>
          <Header />
          <section>
            <h2>Privacy Policy</h2>
            <p>
              <strong>Information We Collect:</strong>
            </p>
            <p>We may employ the use of cookies at our discretion. By accessing swordslinger.org, you agreed to use cookies in agreement with our Privacy Policy.</p>
            <p>
              <strong>How We Use Your Information:</strong> 
            </p>
            <p>We use your personal information to:</p>
            <p>• Process and fulfill your order.</p>
            <p>• Communicate with you about your order.</p>
            {/* <p>• Improve our products and services.</p> */}
            <p>
              <strong>Data Security:</strong>
            </p>
            <p>Your payment information is securely processed through Verifone. We do not store your credit card details.</p>
            <p>
              <strong>Third-Party Services:</strong>
            </p>
            <p>We may use third-party services for analytics and marketing purposes. These third parties may have access to your personal information.</p>
            <p>
              <strong>Cookies:</strong>
            </p>
            <p>We may use cookies to improve your experience on our website.</p>
          </section>
          <Footer />
          <Return />
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default Privacy;
