import React from 'react';
import { Link } from 'react-router-dom';
import './Pre-Order.css';
import preorder_img from '../images/pre-order page_PNG.png';

const PreOrder = () => {

  const handleProceed = () => {
    // Define the functionality for handling the proceed button click
    console.log('Proceed button clicked');
  };

  const PreOrdering = () => {
    return (
      <div className="pre_order_img_container">    
        <img id="pre_order_img" src= {preorder_img} alt="Pre-Order Image" className='hover-effect' />
        <div className = "para">
          <p>Currently, the <strong>audiobook</strong> version of this project is in production. You can help us complete this project by pre-ordering it now. When it is ready to publish, we will send it to you via email.</p>
          <p>As well, we are still looking for a print house for <strong>print</strong> copies of the novel. A pre-order will help us accomplish this faster and ensure the quality of the book itself.
             We intend to have this process comlete by the end of the year, by which time we will have the books ordered, printed, and shipped!</p>
          <p>Thank you for your patience!</p>
        </div>
        <div className="radio-buttons">
          <label id="a">
            <input type="radio" name="orderType" value="audiobook" />
            Audiobook
          </label>
          <label id="b">
            <input type="radio" name="orderType" value="print" />
            Print 
          </label>
        </div>
        <button onClick={handleProceed} className="proceed_button">Proceed</button>  
      </div>
    )
  }

  const Return = () => {
    return (
      <div className="return">
        <Link to="/"><p>Return</p></Link>
      </div>
    )
  }

  const Copyright = () => {
    return (
      <div className="copyright">
        &copy; 2023 Mesantos
      </div>
    )
  }

  return (
    <div className='bark-background'>
      <div className='pseudo-background' />
      
        <div className = "container_2">
          <PreOrdering />
          <Return />
          <Copyright />
        </div>
      </div>
    // </div>
  );
};

export default PreOrder;